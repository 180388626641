import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import Logo from '../icons/android-icon-80x35.png'

export const Title = ({ subTitle, args }) => {
  const translate = useTranslate()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const text = translate(subTitle, { ...args, _: subTitle })

  if (isDesktop) {
    return <span><img src={Logo} alt={'logo'} /> {text ? ` - ${text}` : ''}</span>
  }
  return <span>{text ? text : 'Muztrad'}</span>
}
